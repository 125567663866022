<template>
  <div>
    <div class="vx-col lg:w-1/2" align="left"></div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="docs"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Status</vs-th>
          <vs-th sort-key="areaName">Name</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div class="w-full">
                <vs-switch
                  @change="
                    changeClinicTreatmentAreaStatus(
                      data[indextr].status,
                      data[indextr].idToUpdate
                    )
                  "
                  class="m-2"
                  v-model="data[indextr].status"
                />
              </div>
            </vs-td>
            <vs-td :data="data[indextr].areaName">
              {{ data[indextr].areaName }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div
        class="
          m-2
          flex
          sm:justify-between
          justify-center
          items-center
          flex-wrap
        "
        style="height: 35px padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>

        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="5"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  name: "ClinicApplicableAreaList",
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      docs: [],
      totalDocs: 0,
      page: 1,
      noDataText: "",
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("clinic", [
      "fetchTreatmentAreas",
      "changeTreatmentAreaStatus",
    ]),
    async fetchList() {
      this.$vs.loading();
      this.fetchTreatmentAreas(this.dataTableParams).then((res) => {
        this.$vs.loading.close();
        this.docs = res.data.data[0].docs;
        this.totalDocs = res.data.data[0].pagination[0].total || 0;
        this.page = res.data.data[0].pagination[0].page;
        this.currentPage = res.data.data[0].pagination[0].page;
        this.noDataText = "No Data Available";
        this.serverResponded = true;
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      if (!key) return;

      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    changeClinicTreatmentAreaStatus(status, idToUpdate) {
      this.changeTreatmentAreaStatus({ status, idToUpdate });
    },
  },
  watch: {
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.noDataText = "Loading...";
    this.fetchList();
  },
};
</script>
